<template>
  <v-entity-container
      v-bind="{
        title,
        metricFilters,
        metricFiltersQuery,
        activeProjectId,
        data: listingList,
        tableCellsConfig: filteredTableCellsConfig || tableCellsConfig,
        page: 'listing',
        label: 'Листинги',
        tags
      }"
  />
</template>

<script>
import {mapState} from 'vuex'
import filterWSByProject from '@/mixins/filterWSByProject.js'

import entityContainer from '@/services/components/entityContainer'

export default {
  name: 'listings',

  components: {
    'v-entity-container': entityContainer
  },

  mixins: [filterWSByProject],

  data () {
    return {
      title: {
        primary: 'Data analytics',
        secondary: 'Categories'
      }
    }
  },

  computed: {
    ...mapState({
      listingList: state => state.dashboard.listingList
    }),

    tableCellsConfig() {
      return this.$store.getters['tableSettings/getTableCellsConfigListing']
    },

    metricFilters () {
      let items = [
        'ws2',
        'top3',
        'top5',
        'top10',
        'p_traf',
        'sessions',
        'users',
        'new_users',
        'transactions',
        'conversions',
        'transaction_revenue',
      ]
      if (!this.shouldFilterWSMetrics)  {
        items.push('ws1')
        items.push('ws3')
      }
      return items
    },

    metricFiltersQuery () {
      let filters = []

      this.metricFilters.forEach(filter => {
        filters.push(`${filter}_from`)
        filters.push(`${filter}_to`)
        filters.push(`${filter}_diff_from`)
        filters.push(`${filter}_diff_to`)
      })

      return filters
    },

    tags () {
      const routeQuery = {...this.$route.query}

      const checkIsActive = type => {
        const routeQueryTypes = routeQuery.types
        return !!(routeQueryTypes && ((Array.isArray(routeQueryTypes) && routeQueryTypes.length === 1 && routeQueryTypes[0] === type) || typeof routeQueryTypes === 'string' && routeQueryTypes === type));
      }

      const isActiveListing = checkIsActive('listing')
      const isActiveTagListing = checkIsActive('tag_listing')

      return [
        {
          id: 'listing',
          name: 'Листинги',
          to: {
            name: this.$route.name,
            query: {
              ...routeQuery,
              types: isActiveListing ? undefined : ['listing']
            },
            params: {activeProject: this.activeProjectId}
          },
          isActive: isActiveListing
        },
        {
          id: 'tag_listing',
          name: 'Теговые листинги',
          to: {
            name: this.$route.name,
            query: {
              ...routeQuery,
              types: isActiveTagListing ? undefined : ['tag_listing']
            },
            params: {activeProject: this.activeProjectId}
          },
          isActive: isActiveTagListing
        }
      ]
    },
  }
}
</script>
